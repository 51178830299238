<script>
    import "../../assets/style/icons.css";
    import { fly, fade } from "svelte/transition";
    import Logo from "../components/navs/Logo.svelte";
    import { removeCookie, sendData, setCookie } from "../utils/api";
    let passView = false;
    let pass = '';
    let login = '';
    let secret = '';
    let wait = false;
    let message;

    function send(){
        wait = true;
        console.log({
            login: login,
            password: pass
        });
        sendData('/api/login', {
            login: login,
            password: pass,
            secretcode: secret
        }).then(
            data=>{
                setCookie(
                    'userData',
                    window.btoa(
                        encodeURIComponent(
                            JSON.stringify(
                                data.user
                            )
                        )
                    )
                );
                window.location.href = data.path;
                wait = false;
            },
            err=>{
                console.log(err);
                message = err.message;
                wait = false;
            }
        );
    }
    removeCookie('userData');
</script>

<div class="loginPage">
    <div
        transition:fade
        class="text"
    >
        <div class="logo">
            <Logo />
        </div>
        <p>
            Ресурс предназначен для внутреннего использования сотрудниками компании.<br>
            Пожалуйста авторизируйтесь! 
        </p>
    </div>
    <form
        class="surface form"
        transition:fly="{{ y: -30, duration: 300, delay: 300, opacity:0 }}"
        on:submit|preventDefault={send}
    >
        {#if message}
            <p class="red">
                {message}
            </p>
        {/if}
        <p class="label">
            <label class="reset" for="login">
                Логин:
            </label>
        </p>
        <input name="login" type="text" bind:value={login} />
        <p class="label">
            <label class="reset" for="password">
                Пароль:
            </label>
        </p>    
        <div class="pass">
            {#if passView}
                <input name="password" type="text" class="reset" bind:value={pass} />
            {:else}
                <input name="password" type="password" class="reset" bind:value={pass} />
            {/if}
            <button 
                type="button"
                class="text"
                on:click={()=>{
                    passView = !passView;
                }}
            >
                <i class="icon-eye"></i>
            </button>
        </div>
        <button 
            class="primary filled" 
            disabled={login.length < 5 || pass.length < 5 || wait}
        >
            {#if wait}
                Подождите
            {:else}
                Продолжить
            {/if}
        </button>
    </form>
</div>

<style lang="postcss">
    :global(#app){
        display: flex;
        align-items: center;
        height: 100vh;
        justify-content: center;
    }
    .logo{
        margin: * * 20px;
    }
    .loginPage{
        max-width: 550px;
        margin: * auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & .form{
            max-width: 400px;
            padding: 40px;
            margin: 40px *;

            & > button{
                margin-top: 12px;
            }
        }
        & input{
            width: 100%;
        }
    }
    .text{
        text-align: center;
    }
    .label{
        margin: 0 0 4px;
        &:not(:first-child){
            margin-top: 20px;
        }
    }
    .pass{
        display: flex;
        margin: * * 14px;
        background: none;
        align-items: center;
        border: 1px solid rgba(0,0,0,.25);
        border-radius: 10px;
        color: inherit;
        font: inherit;

        & button{
            size: 36px;
        }
    }
</style>